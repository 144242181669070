// Breakpoints
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1440px;
$layout-breakpoint-small: 960px;
$layout-breakpoint-xsmall: 480px;

// Colors
$background: #f0eff1;
$white: #fff;
$black: #24242a;
