@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #000000;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.lien {
    color: #BDB76B;
    font-size: 16px;
}

.footer {
    width: 300px;
}

.center-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-text {
    text-align: center;
    font-size: 16px;
    /* Add any other styles you want for your centered text */
}

.carousel-container {
    width: 600px;
    height: 300px;
    border-color: #FDCE4D;
    /* Ajustez la hauteur en fonction de vos besoins */
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    height: 60px;
    background-color: #000000;
    position: relative;
}

.menu-icon {
    display: none;
    color: #FDCE4D;
}

.nav-elements {
    z-index: 2;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 60px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #FDCE4D;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
}

@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 600px) {
    .menu-icon {
        display: block;
        cursor: pointer;
    }
    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #000000;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
        z-index: 2;
    }
    .nav-elements.active {
        width: 270px;
    }
    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }
    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.center {
    margin: auto;
    width: 50%;
    text-align: center;
}

.centerbutton {
    justify-content: center;
    align-content: center;
    position: flex-end;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sections {
    padding-left: 10px !important;
    padding-right: 10px !important;
}