body.loading {
    height: 70vh;
    justify-content: center;
}

.loader {
    .loader-inner {
        .image-block {
            position: absolute;
            transform-origin: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .image-1 {
                width: 300px;
                left: 16%;
                bottom: 14%;
                @media (max-width: $layout-breakpoint-xsmall) {
                    left: 4%;
                    bottom: 28%;
                }
            }
            &.image-3 {
                width: 200px;
                right: 12%;
                top: 8%;
                @media (max-width: $layout-breakpoint-xsmall) {
                    top: 16%;
                    right: 4%;
                }
            }
            &.image-4 {
                max-width: 300px;
                width: 40%;
                right: 20%;
                bottom: 10%;
                @media (max-width: $layout-breakpoint-xsmall) {
                    right: 6%;
                    bottom: 32%;
                }
            }
            &.image-5 {
                width: 180px;
                left: 14%;
                top: 12%;
                @media (max-width: $layout-breakpoint-xsmall) {
                    left: 6%;
                    top: 18%;
                }
            }
            img {
                width: 80%;
                object-fit: fill;
            }
            .image-5 {
                width: 800px;
                height: 400px;
                top: 12%;
            }
        }
    }
}